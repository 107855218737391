.cesium-viewer-bottom {
  display: none !important;
}

.cesium-infoBox {
  color: #262626 !important;
  top: 126px !important;
  right: 60px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  background: white !important;
}

.cesium-infoBox-title {
  height: auto !important;
  padding: 4px 30px 0px 11px !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  text-align: left !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  background: white !important;
}

button.cesium-infoBox-camera {
  display: none !important;
}

button.cesium-infoBox-close {
  width: 24px !important;
  height: 24px !important;
  padding: 0 4px !important;
  color: #010305 !important;
  font-size: 14px !important;
  top: 4px !important;
  right: 4px !important;
  border-radius: 9999px !important;
  background: #d0d0d0 !important;
}

.cesium-infoBox-iframe .cesium-infoBox-defaultTable th {
  text-align: left !important;
}
